<template>
  <sign-page
    title-text="用户"
    :request="request"
    :column-list="columnList"
    @tableAction="tableAction"
    :table-actions="tableActions"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  userRequest as request,
  resetPassword,
  ucenterRequest
} from '@/api'

export default {
  computed: {
    tableActions () {
      let data = [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      },
      {
        key: 'reset_pwd',
        label: '重置密码'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '姓名',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '账号',
          key: 'phone',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '角色',
          key: 'roleId',
          selectDatas: this.$store.getters.roleList,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '主体',
          key: 'mainbodyId',
          selectDatas: this.$store.getters.mainbodyList
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = [{
          type: 'input',
          label: '姓名',
          key: 'name',
          fmDisabled: true
        },
        {
          type: 'input',
          label: '账号',
          key: 'phone',
          fmDisabled: true
        },
        {
          type: 'select',
          label: '角色',
          key: 'roleId',
          selectDatas: this.$store.getters.roleList,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '主体',
          key: 'mainbodyId',
          selectDatas: this.$store.getters.mainbodyList
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          field: 'name',
          sort: true,
          title: '姓名'
        },
        {
          title: '账号',
          field: 'phone',
          sort: true
        },
        {
          title: '角色',
          field: 'roleNames',
          sort: true
        },
        {
          title: '',
          field: 'mainbody'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        update: this.update,
        get: this.loadData,
        add: this.add
      }),
      chooseData: {}
    }
  },
  created () {
    this.$store.dispatch('loadMainbodyList')
    this.$store.dispatch('loadRoleList')
  },
  methods: {
    async loadData (parm) {
      await this.$store.dispatch('loadMainbodyList')
      let datas = await request.get(parm)
      datas.forEach(v => {
        v.roleId = v.roles.length > 0 ? v.roles[0].id : null
        v.roleNames = v.roles.map(v => v.name).join(',')
        v.mainbodyId = v.roles.length > 0 && v.roles[0].authorities.length > 0 ? v.roles[0].authorities[0].objId : null
        v.mainbody = (this.$store.getters.mainbodyList.find(v1 => v1.key === v.mainbodyId) || {label: ''}).label
      })
      return datas
    },
    async update (id, parm) {
      parm.roleIds = [parm.roleId]
      await request.update(id, parm)
      let parm2 = [{
        objType: 'mainbody',
        ids: parm.mainbodyId ? [parm.mainbodyId] : [],
        roleId: parm.roleId
      }]
      await ucenterRequest.updateAuthorities(id, parm2)
    },
    async add (parm) {
      parm.roleIds = [parm.roleId]
      let dataId = await request.add(parm)
      let parm2 = [{
        objType: 'mainbody',
        ids: parm.mainbodyId ? [parm.mainbodyId] : [],
        roleId: parm.roleId
      }]
      await ucenterRequest.updateAuthorities(dataId, parm2)
    },
    async tableAction (data) {
      if (data.action === 'reset_pwd') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定重置用户 ' + data.data.name + ' 的密码吗?'})
        if (result) {
          resetPassword(data.data.id).then(() => {
            this.$notice.success({
              title: '系统提示',
              desc: '密码重置成功'
            })
          })
        }
      }
    }
  }
}
</script>